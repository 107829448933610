.psv-markers {
  user-select: none;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.psv-markers-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.psv-marker {
  display: none;
}
.psv-marker--normal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background-size: contain;
  background-repeat: no-repeat;
}
.psv-marker--transparent {
  display: block;
  opacity: 0;
}
.psv-marker--visible {
  display: block;
}
.psv-marker--has-tooltip, .psv-marker--has-content {
  cursor: pointer;
}